/* global css start */
* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #030207;
  /* background-image: url('../public/images/body-bg.png'); */
  background-repeat: repeat;
  background-size: contain;

}

a {
  text-decoration: none;
  display: inline-block;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Orbitron', sans-serif;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  width: auto;
}

.btns a, .btns button {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 54px;
  padding: 12px 29px;
  cursor: pointer;
}

.btns a img, .btns button img {
  margin-right: 12px;
}

.list-asset button {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 3px 8px;
  cursor: pointer;
}

.btns .explore-btn {
  background: transparent;
  border: 1px solid #54FFF5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

h1 {
  font-weight: 600;
  font-size: 45px;
  line-height: 68px;
  color: #FFFFFF;
}

h1 span {
  font-weight: 700;
  font-size: 55px;
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
}

h2 {
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h2 span {
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.8);
}

.border-btn{
  background: transparent;
  border: 1px solid #54FFF5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 9px 24px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.Toastify__toast{
  background-color: #090823 !important;
  border-radius: 10px;
  padding-left: 51px !important;
  background-size: 23px !important;
  background-position: 16px calc(50% - 1px) !important;
  background-repeat: no-repeat !important;
}

.Toastify__toast-container--bottom-left .Toastify__toast{
  background-image: none !important;
  padding-left: 21px !important;
}

.Toastify__toast.Toastify__toast--success{
  background-image: url('../public/images/success-icon.png');
}

.Toastify__toast.Toastify__toast--error{
  background-image: url('../public/images/error-icon.png');
}

.Toastify__toast.Toastify__toast--warning{
  background-image: url('../public/images/warning-icon.png');
}

.Toastify__toast.Toastify__toast--success .Toastify__progress-bar{
  background-color: #04E952;
}

.Toastify__toast.Toastify__toast--error .Toastify__progress-bar{
  background-color: #FF2323;
}

.Toastify__toast.Toastify__toast--warning .Toastify__progress-bar{
  background-color: #FFA800;
}

.Toastify__toast.Toastify__toast--success .Toastify__toast-icon svg{
  color: #04E952;
}

.Toastify__toast.Toastify__toast--error .Toastify__toast-icon svg{
  color: #FF2323;
}

.Toastify__toast.Toastify__toast--warning .Toastify__toast-icon svg{
  color: #FFA800;
}

/* global css end */

/* header css start */
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 30px 0;
}

header .navbar-brand {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF !important;
}

header .navbar-brand img{
  width: 70px;
}

header ul {
  gap: 30px;
  align-items: center;
}

header .nav-link {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #FFFFFF !important;
  padding: 0 !important;
}

header .wallet-btn {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #FFFFFF;
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: auto;
  padding: 10px 16px !important;
}

/* header css end */

/* banner css start */
.banner {
  background: url(../public/images/banner-bg.jpg) no-repeat;
  background-size: cover;
  padding: 180px 0 120px;
}

.banner h1 {
  margin-bottom: 20px;
  max-width: 600px;
}

.banner p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 46px;
  max-width: 600px;
}

.banner .btns {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 40px;
}


.banner ul {
  display: flex;
  align-items: center;
  gap: 76px;
  text-align: center;
}

.banner h6 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.banner ul span {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  display: block;
}

/* banner css end */

/* about css start */
.about {
  padding: 114px 0;
}

.about h2 {
  margin-bottom: 16px;
}

.about p {
  margin-bottom: 52px;
}

.about a {
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  padding: 8.5px 18.5px;
}

/* about css end */
.common-content{
  padding-top: 180px;
}

.common-content .content{
  max-width: 1000px;
  margin: 0 auto;
}

.common-content .content h2{
  margin-bottom: 20px;
}

.common-content .content p{
  margin-bottom: 50px;
}

.common-content .content p:last-child{
  margin-bottom: 0;
}
/* work css start */
.work .title-row {
  margin-bottom: 55px;
}

.work h2 {
  margin-bottom: 16px;
}

.work .work-card {
  background: #090823;
  border-radius: 0px 30px 0px 30px;
  padding: 18px 20px 37px;
  margin-bottom: 36px;
}

.work .work-card h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  margin: 23px 0 8px;
}

.work .work-card p {
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  margin-bottom: 28px;
}

.work .btns {
  display: flex;
  align-items: center;
  gap: 16px;
}

.work .btns a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  padding: 6px 20px;
}

.work .btns .explore-btn {
  padding: 6px 12px;
}

/* work css end */

/* marketplace css start */
.marketplace {
  padding: 114px 0;
}

.marketplace h2 {
  margin-bottom: 16px;
}

.marketplace p {
  margin-bottom: 52px;
}

.marketplace a {
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  padding: 8.5px 18.5px;
}

/* marketplace css end */

/* projects css start */
.projects {
  padding: 40px 0;
}

.projects .title-row {
  margin-bottom: 55px;
}

.projects h2 {
  margin-bottom: 16px;
}

.projects .detail-row {
  background: #090823;
  border-radius: 10px;
  padding: 16px 16px 22px;
}

.projects .project-card {
  border-radius: 10px;
  padding: 18px 16px 25px;
  border: 1px solid #54FFF5;
}

.projects .btns a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  padding: 6px 20px;
}

.projects .project-card h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  margin: 23px 0 8px;
}

.projects .project-card p {
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 22px;
}

/* projects css end */

/* activity css start */
.activity {
  padding: 40px 0;
}

.activity .title-row {
  margin-bottom: 55px;
}

.activity .title-row h2 {
  margin-bottom: 16px;
}

.activity .activity-slider .thumbnail {
  height: 600px;
}

.activity .activity-slider .thumbnail img {
  height: 100%;
  width: 100%;
}

.activity .activity-slider .slick-list {
  padding: 0 60px 0 0 !important;
}

.activity .activity-slider .item {
  margin: 0 54px 0 0;
  position: relative;
}

.activity .activity-slider .content {
  position: absolute;
  bottom: 48px;
  left: 50px;
}

.activity .activity-slider h3 {
  font-weight: 600;
  font-size: 45px;
  line-height: 56px;
  color: #FFFFFF;
}

.activity .activity-slider .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid #F5F5F5;
  backdrop-filter: blur(5px);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* activity css end */

/* research css start  */
.research {
  padding: 40px 0;
}

.research p, .activity p, .projects p, .work p{
  max-width: 700px; 
  margin: 0 auto;
}

.research .title-row {
  margin-bottom: 33px;
}

.research .title-row h2 {
  margin-bottom: 16px;
}

.research .detail-row {
  background: #090823;
  border-radius: 10px;
  padding: 14px 14px 18px;
  max-width: 724px;
  margin: 0 auto;
}

.research textarea {
  background: #090B1C;
  border-radius: 10px;
  width: 100%;
  height: 280px;
  padding: 16px 24px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(162, 160, 255, 0.8);
  resize: none;
  display: block;
  outline: none;
  border: 1px solid #54FFF5;
}

.research button {
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  max-width: 237px;
  width: 100%;
  margin: 16px auto 0;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  border: none;
}

.main-wrapper{
  padding-top: 150px;
}

.main-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
}

.main-head h1{
  background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.collection-main-head{
  display: flex;
  align-items: center;
}

.collection-main-head > img{
  width: 128px;
  height: 128px;
  border-radius: 4px;
}

.collection-main-head > div{
  padding-left: 32px;
}

.collection-wrap .collection-box{
  border-radius: 10px;
  border: 1px solid #08B3D6;
  background: #090823;
  padding: 16px;
  margin-bottom: 24px;
}

.collection-wrap .collection-box > img{
  border-radius: 10px;
  height: 250px;
  object-fit: cover;
  width: 100%;
}

.collection-wrap .collection-box .content{
  padding-top: 24px;
}
.collection-wrap .collection-box .content .collection-info{
  display: flex;
}

.collection-wrap .collection-box .content .collection-info img{
  width: 48px;
  height: 48px;
  border-radius: 4px;
}

.collection-wrap .collection-box .content .collection-info .info{
  padding-left: 16px;
  width: calc(100% - 48px);
}

.collection-wrap .collection-box .content .collection-info .info h3{
  color: #FFF;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.collection-wrap .collection-box .content .collection-info .info p{
  background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
color: #fff;
font-size: 18px;
font-weight: 600;
}

.collection-wrap .collection-box .content .btns{
  text-align: center;
  margin-top: 20px;
}

.collection-wrap .collection-box .content .btns a{
  height: auto;
  padding: 7px 24px;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.collection-wrap .collection-box .content .btns a img{
 margin-right: 12px;
}

.collection-wrap .load-more-btn{
  margin-top: 64px;
 }
/* research css end  */

/* footer css start */
footer {
  padding: 104px 0 126px;
}

footer .footer-logo {
  font-weight: 700;
  font-size: 50px;
  line-height: 63px;
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 23px;
}

footer h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  margin-bottom: 21px;
}

footer ul li a {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF !important;
  margin-bottom: 8px;
}

footer .social-links {
  display: flex;
  align-items: center;
  gap: 21px;
  margin-bottom: 28px;
}

footer .copyright {
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

/* footer css end */

/* market css start */
.market {
  padding: 167px 0 50px;
}

.market .title-row {
  margin-bottom: 95px;
}

.market .title-row h1 {
  margin-bottom: 16px;
}

.market .title-row p {
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

.market .detail-card {
  background: #090823;
  border-radius: 10px;
  padding: 16px;
  border: 1px solid #54FFF5;
  margin-bottom: 24px;
}

.detail-card .thumbnail img {
  min-height: 335px;
  max-height: 335px;
}

.market .detail-card h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  margin: 16px 0 24px;
}

.market .detail-card ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.market .detail-card .price {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.market .detail-card .buy-now-btn {
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  border-radius: 5px;
  height: 43px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 24px;
}

.market .load-more-btn {
  margin-top: 40px;
  text-align: center;
}

.market .load-more-btn a {
  background: transparent;
  border: 1px solid #54FFF5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 44px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  padding: 8px 21px;
  border-radius: 5px;
}

/* market css end */


.collection-detail .main-img-wrap img{
  width: 100%;
  border-radius: 8px;
}

.collection-detail .collection-main-info{
  padding-left: 40px;
}

.collection-detail .collection-main-info h2{
  margin-bottom: 20px;
}

.collection-detail .collection-main-info p{
  margin-bottom: 30px;
}

.collection-detail .collection-main-info .price{
  border-radius: 8px;
  background: #220532;
  margin-bottom: 20px;
  border: 1px solid #444;
}

.collection-detail .collection-main-info .price .price-card{
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.collection-detail .collection-main-info .price .price-card input{
  color: #fff;
  background-color: transparent !important;
  border: 1px solid #fff;
  border-radius: 100px;
  text-align: center;
  height: 40px;
} 

.collection-detail .collection-main-info .price .price-card h5{
  font-size: 16px;
}

.collection-detail .collection-main-info .btns{
  margin-top: 40px;
}

.collection-detail .collection-main-info .btns a{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.minting-collection > span{
  display: block;
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
}

.minting-collection .progress-bar-wrap {
  margin-bottom: 35px;
}

.minting-collection .progress-bar div {
  height: 8px !important;
  border-radius: 8px !important;
}

.minting-collection .progress-bar div span {
  display: none !important;
}

.minting-collection .progress-bar>div {
  background: #343444 !important;
}

.minting-collection .progress-bar>div>div {
  background: #ac22ff !important;
}

.minting-collection .progress-bar-wrap .progressbar-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}

.minting-collection .progress-bar-wrap .progressbar-head span {
  color: #EBEBEB;
  font-size: 15px;
  font-weight: 600;
}

.minting-collection .progress-bar-wrap .progressbar-head span b {
  font-weight: bold;
  color: #fff;
  font-size: 18px;
}

.public-stage-wrapper {
  margin-bottom: 32px;
}

.public-stage-wrapper h3 {
  color: #FFF;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
}

.public-stage-wrapper .inner {
  border-radius: 8px;
  background: #220532;
  margin-bottom: 20px;
  border: 1px solid #444;
  padding: 24px;
}

.public-stage-wrapper .inner .limit-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.public-stage-wrapper .inner .limit-wrap p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 1.4px;
}

.public-stage-wrapper .inner .limit-wrap span {
  color: #8A8AA0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.public-stage-wrapper .inner .limit-wrap .common-btn {
  color: #5142FC;
  width: 153px;
}

.public-stage-wrapper .inner .limit-wrap .common-btn:hover {
  color: #fff;
}

.public-stage-wrapper .inner .limit-wrap .form-group {
  position: relative;
}

.public-stage-wrapper .inner .limit-wrap .form-group input {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 50px;
  height: 46px;
  text-align: center;
  padding: 10px 60px;
  width: 230px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.public-stage-wrapper .inner .limit-wrap .form-group input::-webkit-outer-spin-button,
.public-stage-wrapper .inner .limit-wrap .form-group input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.public-stage-wrapper .inner .limit-wrap .form-group img {
  position: absolute;
  top: 3px;
  width: 40px;
  height: 40px;
  left: 3px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  z-index: 1;
}

.public-stage-wrapper .inner .limit-wrap .form-group img:hover {
  opacity: 0.85;
}

.public-stage-wrapper .inner .limit-wrap .form-group img.increment {
  left: auto;
  right: 3px;
}


/* popup css start */
.stake-unstake .modal-dialog{
  max-width: 404px;
}

.store-modal .modal-dialog{
  max-width: 560px;
}

.store-modal .modal-body img{
  width: 100%;
  border-radius: 4px;
}

.modal-dialog {
  max-width: 640px;
  width: 100%;
}

.modal-content {
  border-radius: 16px;
  border: 0.5px solid #00CECE;
  background: #090823;
  max-width: 640px;
  width: 100%;
}

.modal-header {
  display: block;
  border: none;
  padding: 35px 48px 0;
  position: relative;
}

.modal-header .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  background: url(../public/images/close-btn.svg) no-repeat;
  padding: 0;
  width: 24px;
  height: 24px;
  margin: 0;
}

.modal-header h2 {
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 18px;
}

.modal-header p {
  color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
}

.modal-body {
  padding: 15px 48px 48px;
}

.modal-body .upload-card {
  border-radius: 8px;
  background-color: #15142E;
  text-align: center;
  padding: 168px 24px;
  margin-bottom: 24px;
}

.modal-body h4 {
  color: #FFF;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  opacity: 0.3;
  margin-bottom: 24px;
}

.modal-body p {
  color: #FFF;
  font-family: Poppins;
  font-weight: 400;
  margin-bottom: 28px;
}

.modal-body button {
  color: #FFF;
  font-family: Poppins;
  font-weight: 500;
  padding: 8px 20px;
  border: 1px solid #00CECE;
  border-radius: 5px;
}

.execute-proposal .modal-body button{
  margin: 0 auto;
}

.modal-body .btn-wrap{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.modal-body .btn-wrap button{
  width: 47%;
  margin: 0 !important;
}

.modal-body form input, .modal-body form select, .modal-body form textarea {
  border-radius: 10px;
  background: #090B1C;
  padding: 16px;
  color: rgba(255, 255, 255, 0.3);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  display: block;
  margin-bottom: 16px;
  border: 1px solid #00CECE;
  outline: none;
}

.modal-body button[type=submit] {
  background: linear-gradient(204deg, #0CF9D0 0%, #07A8D6 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 48px auto 0;
}
.modal.minting-engine{
  padding: 0 !important;
}
.modal.minting-engine .modal-dialog{
  max-width: 480px;
  width: 95%;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.modal.minting-engine .modal-body button{
  width: 120px;
  margin: 40px auto 0;
}

.modal.minting-engine .info-wrap .info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px !important;
  background-color: #090B1C !important;
  backdrop-filter: blur(10px);
  border: 1px solid #08B3D6 !important;
  padding: 13px 24px !important;
  font-family: 'Inter', sans-serif;
  width: 100%;
  margin-bottom: 20px;
  padding: 13px 12px !important;
}

.modal.minting-engine .info-wrap .info p{
  color: #FFF;
  font-size: 16px;
  opacity: 0.4;
  font-style: italic;
  margin: 0;
}

.modal.minting-engine .info-wrap .info p:last-child{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 130px;
}
  
/* popup css end */


/************** New Pages ****************/
.common-btn{
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  background: linear-gradient(266.54deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 8px 31px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 0 !important;
  justify-content: center;
}

.common-btn-tprnt{
  border-radius: 5px;
  border: 1px solid var(--multiverse-btnz, #0CF9D0);
  background: transparent !important;
}

.common-btn img{
  margin-left: 10px;
}

header.topbar{
    padding: 10px 0;
    position: static;
}

header.topbar .navbar{
    padding: 0 !important;
    justify-content: flex-end;
    align-items: center;
}

header.topbar .navbar .logo{
  display: none;
}

header.topbar .navbar-collapse{
    flex-grow: inherit;
}

header.topbar .navbar-collapse .navbar-nav{
    border-radius: 24px;
    background: #01152D;
    padding: 4px;
}

header.topbar .navbar-collapse .navbar-nav li a{
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 24px;
    border-radius: 28px;
}

header.topbar .navbar-collapse .navbar-nav li.active a{
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
}

header.topbar .profile{
    display: flex;
    align-items: center;
    margin-left: 64px;
}

header.topbar .profile span{
    color: #FFF;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    margin-right: 18px;
}

header.topbar .profile .img-wrap{
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
    box-shadow: 0px 8px 36px 0px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    padding: 2px;
}

header.topbar .profile .img-wrap img{
   width: 44px;
   height: 44px;
}

header.topbar form{
    border-radius: 8px;
    background: #090823;
    backdrop-filter: blur(10px);
    padding: 16px 24px 16px 60px;
    display: flex;
    align-items: center;
     background-size: 21px;
    background-repeat: no-repeat;
    background-position: 24px 50%;
    background-image: url('../public/images/personalcard.png');
}

header.topbar form button{
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    width: 128px !important;
    outline: 0 !important;
    box-shadow: none !important;
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: none !important;
    font-style: normal !important;
    font-weight: 500 !important;
    border: 0 !important;
    height: 24px;
    text-align: left;
    overflow: hidden;
}

form label{
    color: #FFF;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    margin: 0 !important;
    margin-right: 16px !important;
    opacity: 0.4;
}

header.topbar form input.form-control{
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    width: 128px !important;
    outline: 0 !important;
    box-shadow: none !important;
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: none !important;
    font-style: normal !important;
    font-weight: 500 !important;
    border: 0 !important;
    text-align: left;
}    

header.topbar form label{
  margin-top: 2px !important;
}


header.topbar form input.form-control::-webkit-input-placeholder { /* Edge */
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: none !important;
    font-style: normal !important;
    font-weight: 300 !important;
}

header.topbar form input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: none !important;
    font-style: normal !important;
    font-weight: 300 !important;
}

header.topbar form input.form-control::placeholder {
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: none !important;
    font-style: normal !important;
    font-weight: 300 !important;
}

/* header.topbar end */


.account{
    display: flex;
}

.account.kyc .radio-wrapper{
  display: flex;
}

.account.kyc .radio-wrapper .form-group{
  margin: 0 30px 10px 0;
}

.account p{
  font-size: 16px;
}

.account .left{
    min-height: 100vh;
    /* width: 480px; */
    width: 33.334%;
    padding: 30px 50px;
    box-shadow: 80px 0px 150px 0px rgba(170, 20, 240, 0.20);
    position: relative;
    z-index: 1;
    overflow: auto;
}

.account .left .inner{
    max-width: 308px;
    margin: 0 auto;
}

.account .left .inner .head{
    margin-bottom: 48px;
}

.account .left .inner .head h1{
  margin-bottom: 14px;
}

.account .left .inner .common-btn{
    margin-top: 48px;
}

.account .left .inner .logo{
    margin-bottom: 85px;
}

.account .left .inner .logo img{
 width: 60px;
}

form input, form textarea, form select{
    border-radius: 10px !important;
    background-color: #090B1C !important;
    backdrop-filter: blur(10px);
    color: rgba(255,255,255,0.4) !important;
    font-size: 16px !important;
    font-style: italic;
    font-weight: 400 !important;
    border: 1px solid #08B3D6 !important;
    padding: 13px 24px !important;
    font-family: 'Inter', sans-serif;
    width: 100%;
}

form textarea{
  height: 150px;
}

form input::-webkit-input-placeholder { /* Edge */
    color: rgba(255,255,255,0.4) !important;
    font-size: 16px !important;
    font-weight: 400;
}

form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(255,255,255,0.4) !important;
    font-size: 16px !important;
    font-weight: 400;
}

form input::placeholder {
    color: rgba(255,255,255,0.4) !important;
    font-size: 16px !important;
    font-weight: 400;
}

form input[type="password"]{
    padding-right: 50px !important;
}

form input[type="password"] + .eye{
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
}

form > div{
    position: relative;
}

form > a{
  background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    transition: all ease-in-out 0.2s;
    margin-top: 24px;
    font-weight: 600;
    position: relative;
}

form > a:after{
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
    position: absolute;
    width: 100%;
    height: 1px;
    content: '';
    bottom: 0;
    left: 0;
}

form > a:hover{
    opacity: 0.85;
}


.account .right{
    width: 66.666%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
}

.account.signin .right, .account.signup .right{
    background-image: url('../public/images/login-bg.png');
}

.account.sidebar .right{
    display: block;
    width: 80%;
}

.account.sidebar .left{
    width: 20%;
    padding: 43px 0 30px 30px;
}

.account.sidebar .left .inner{
    max-width: 250px;
    margin-left: auto;
    margin-right: 0;
}

.account.sidebar .left .navbar-nav{
    max-width: 280px;
    margin-left: auto;
}

.account.sidebar .left .navbar-nav li{
    margin-bottom: 16px;
}

.account.sidebar .left .navbar-nav li:last-child{
    margin-bottom: 0;
}

.account.sidebar .left .navbar-nav li a{
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 24px;
    border-radius: 6px 0 0 6px;
    display: flex;
    align-items: center;
}

.account.sidebar .left .navbar-nav li a img{
    margin-right: 12px;
}

.account.sidebar .left .navbar-nav li a.active,
.account.sidebar .left .navbar-nav li a:hover{
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
}
.account.kyc .right{
    align-items: flex-end;
    justify-content: flex-start;
    background-image: url('../public/images/kyc-bg.png');
}

.account .right > span{
    opacity: 0.1;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 160px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1;
}

form .swap-icon{
  cursor: pointer;
}

.form-wrap{
    border-radius: 10px;
    background: #090823;
    text-align: center;
    padding: 48px;
    max-width: 404px;
    margin: 114px auto 30px;
}

.form-wrap form input{
  background-color: #090B1C !important;
}

.form-wrap form button{
    margin: 48px auto 0;
}
.form-wrap .head{
    margin-bottom: 32px;
}

.form-wrap .head h1{
    font-size: 32px;
    margin-bottom: 16px;
    line-height: 1.3;
}

.form-wrap .nav-tabs{
    border: 0 !important;
    background: #fff;
    border-radius: 6px;
    padding: 4px;
    display: inline-flex;
    margin-bottom: 24px;
}

.form-wrap .nav-tabs li button{
    border: 0 !important;
    border-radius: 6px;
    color: #030207;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 24px;
}

.form-wrap .nav-tabs li button.active{
    border: 0 !important; 
    background: var(--grdnt, linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%));
    color: #FFF;
}

.form-wrap.minting{
    max-width: 476px;
}

.form-wrap.minting form .form-group{
    border-radius: 10px;
    border: 1px solid #08B3D6;
    background: #090B1C;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
}

.form-wrap.minting form label{
    font-style: italic;
    font-family: 'Inter', sans-serif;
}

.form-wrap.minting form input{
    background-color: transparent !important;
    border-radius: 0 !important;
    text-align: right;
    padding: 0 !important;
    max-width: 130px;
    border: 0 !important;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url(../public/images/calendar.png) no-repeat;
  width: 18px;
  height: 20px;
  cursor: pointer;
}
/* DAO */
.dao-proposal{
    min-height: calc(100vh - 118px);
    display: flex;
    padding-top: 65px;
}

.dao-proposal .container .inner{
    border-radius: 10px;
    background: #090823;
    padding: 24px;
}

.dao-proposal .table-wrapper {
    overflow: auto;
  }
  
 .dao-proposal .table-wrapper table {
    min-width: 700px;
  }
  
  .dao-proposal .table-wrapper table {
    min-width: 1000px;
  }
  
  .dao-proposal .filters{
    margin-bottom: 32px;
  }

  .dao-proposal .filters form{
    display: flex;
    justify-content: space-between;
  }

  .dao-proposal .filters form .form-group{
    width: calc(100% - 257px);
  }

  .dao-proposal .filters form button{
    width: 233px;
  }

  .filters form input[type="search"]{
    background-image: url(../public/images/search-normal.png);
    background-position: calc(100% - 24px) 50%;
    background-repeat: no-repeat;
    background-size: 24px;
    background-color: #090B1C !important;
    padding-right: 60px !important;
  }
  
 .dao-proposal .table-wrapper h2 {
    text-align: left;
  }
  
 .dao-proposal .table-wrapper table {
    width: 100%;
  }
  
 .dao-proposal .table-wrapper table thead {
    border-color: #ffffff1a;
    text-align: left;
  }
  
 .dao-proposal .table-wrapper table thead th {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
 .dao-proposal .table-wrapper table tbody td {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    border: none;
    padding: 16px;
  }
  
 .dao-proposal .table-wrapper table tbody td.status {
    color: rgba(255, 255, 255, 0.4);
  }
  
 .dao-proposal .table-wrapper table tbody td.status.green {
    color: #05FF00;
  }
  
 .dao-proposal .table-wrapper table tbody tr.active td.status {
    color: #05FF00;
  }
  
 .dao-proposal .table-wrapper table tbody tr td.vote-reject img {
    width: 20px;
    height: 20px;
  }
  
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject img {
    transition: all ease-in-out 0.2s;
    cursor: pointer;
  }
  
 .dao-proposal .table-wrapper table tbody tr td.vote-reject img.expired {
    cursor: not-allowed;
  }
  
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject img.expired {
    display: none;
  }
  
 .dao-proposal .table-wrapper table tbody tr:not(.active) td.vote-reject img.expired {
    display: inline-block;
  }
  
 .dao-proposal .table-wrapper table tbody tr:not(.active) td.vote-reject img:not(.expired),
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject img.rejected,
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject img.voted {
    display: none;
  }
  
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.do-vote {
    display: none;
  }
  
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.voted {
    display: inline-block;
  }
  
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.do-reject {
    display: none;
  }
  
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.rejected {
    display: inline-block;
  }
  
 .dao-proposal .table-wrapper table tbody tr.active td.vote-reject img:hover {
    opacity: 0.8;
  }
  
 .dao-proposal .table-wrapper table tbody td.options {
    display: flex;
    align-items: center;
    gap: 35px;
  }
  
 .dao-proposal .table-wrapper table tbody td.options a{
    border-radius: 2px;
      background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
      color: #FFF;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      padding: 5px 8px;
  }
  
 .dao-proposal .table-wrapper table tbody td.options img {
    cursor: pointer;
  }
  
 .dao-proposal .detail-row form input {
    margin: 0;
  }
  
 .dao-proposal .table-wrapper table tbody td.vote-count p {
    display: inline-block;
    color: rgba(255, 255, 255, 0.40);
    font-size: 12px;
    margin-right: 20px;
  }
  
 .dao-proposal .table-wrapper table tbody td.vote-count p{
    width: 115px;
    font-size: 12px !important;
  }
  
 .dao-proposal .table-wrapper table tbody td.vote-count p:last-child {
    display: inline-block;
    margin-right: 0;
    width: 130px;
  }
  
 .dao-proposal .table-wrapper table tbody td.vote-count p img {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }
  
 .dao-proposal .table-wrapper table tbody td.vote-reject p {
    display: inline-block;
    margin-right: 24px;
    vertical-align: middle;
  }
  
 .dao-proposal .table-wrapper table tbody td.vote-reject p:last-child {
    margin-right: 0;
  }

  .dao-proposal .table-wrapper table button{
    padding: 6px 20px;
    min-width: initial;
    line-height: 1.4;
    font-size: 15px;
  }

  /* Stake */

  .collection-wrap{
    margin-top: 40px;
    margin-bottom: -50px;
  }

  .colletion-box{
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
  }

  .colletion-box:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(1, 21, 45, 0.00) 0%, #01152D 100%);
  }

  .colletion-box > img, .colletion-box > a > img{
    width: 100%;
    height: 450px;
    object-fit: cover;
  }

  .colletion-box .content{
    position: absolute;
    bottom: 0;
    padding: 20px;
    width: 100%;
  }

  .colletion-box .content .content-head{
    display: flex;
    align-items: flex-start;
  }

  .colletion-box  .content-head img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }

 .colletion-box .content-head > div{
    padding-left: 12px;
    width: calc(100% - 48px);
  }

  .colletion-box .content-head h3{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
    color: #fff;
  }

  .colletion-box .content-head span{
    font-size: 16px;
  }
  
  .colletion-box .btns-wrapper{
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }

  .colletion-box .btns-wrapper .stake-unstake{
    display: flex;
    background: #fff;
    border-radius: 6px;
    padding: 4px;
  }

  .colletion-box .btns-wrapper .stake-unstake button{
    background-color: transparent !important;
    border: 0 !important;
  }

  .colletion-box .btns-wrapper button.active,
  .colletion-box .btns-wrapper button:hover{
    color: #fff !important;
  }

  .colletion-box .btns-wrapper button{
    color: #061C35;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 22px;
    border-radius: 6px;
  }

  .colletion-box .btns-wrapper > button{
    border: 1px solid #0CF9D0 !important;
    background: #061C35 !important;
    color: #fff !important;
  }

  .colletion-box .btns-wrapper button.active,
  .colletion-box .btns-wrapper button:hover,
  .colletion-box .btns-wrapper > button.active,
  .colletion-box .btns-wrapper > button:hover{
    color: #fff !important;
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%) !important;
  }

  .sidebar-arrow {
    width: 35px;
    height: 35px;
    background: linear-gradient(267deg, #0CF9D0 -1.3%, #07A8D6 111.87%);
    display: block;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0;
    transition: all ease-in-out 0.3s;
    z-index: 10;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.5);
    border: 0 !important;
  }
  
  .sidebar-arrow img {
    transform: rotate(-90deg);
    transition: all ease-in-out 0.3s;
  }
  
  .sidebar-arrow.active {
    left: 310px;
  }
  
  .sidebar-arrow.active img {
    transform: rotate(90deg);
  }

  .bg-open {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 8;
  }

.approve-kyc-wrap{
  padding-top: 65px;
}

.approve-kyc-wrap .filters form [type="radio"] {
    position: absolute;
    left: -9999px;
}
.approve-kyc-wrap .filters form  [type="radio"]:checked + label,
.approve-kyc-wrap .filters form  [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #fff;
    opacity: 1;
    margin: 0 !important;
}
.approve-kyc-wrap .filters form  [type="radio"]:checked + label:before,
.approve-kyc-wrap .filters form  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    border-radius: 100%;
}
.approve-kyc-wrap .filters form  [type="radio"]:checked + label:after{
    content: '';
    width: 6px;
    height: 6px;
    background: #fff;
    position: absolute;
    top: 7px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.approve-kyc-wrap .filters form{
  display: flex;
  align-items: center;
}

.approve-kyc-wrap .filters form .radio-wrapper{
  width: calc(100% - 350px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.approve-kyc-wrap .filters form .radio-wrapper .form-group{
  margin-right: 40px;
}

.approve-kyc-wrap .filters form > .form-group{
  width: 350px;
}

.approve-kyc-wrap .filters{
  margin-bottom: 24px;
}

.approve-kyc-wrap .inner{

  padding: 24px;
  border-radius: 10px;
  background: #090823;
}

.approve-kyc-wrap .inner h1{
  margin-bottom: 24px;
}

.approve-kyc-wrap .inner span{
  display: block;
  color: #fff;
  font-family: 'Orbitron', sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 600;
}

.approve-kyc-table{
  overflow: auto;
}

.approve-kyc-table table{
  width: 100%;
  min-width: 1000px;
}

.approve-kyc-table table th{
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-family: 'Orbitron', sans-serif;
  padding: 18px 24px;
  border: 1px solid rgba(255,255,255,0.1);
  border-left: 0;
  border-right: 0;
}

.approve-kyc-table table td{
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 17px 24px;
}

.approve-kyc-table table td button{
  padding: 6px 20px;
  line-height: 1.4;
  font-size: 14px;
}


.radio-wrapper [type="radio"] {
  position: absolute;
  left: -9999px;
}
.radio-wrapper  [type="radio"]:checked + label,
.radio-wrapper  [type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #fff;
  opacity: 1;
  margin: 0 !important;
}
.radio-wrapper  [type="radio"]:checked + label:before,
.radio-wrapper  [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  border-radius: 100%;
}
.radio-wrapper  [type="radio"]:checked + label:after{
  content: '';
  width: 6px;
  height: 6px;
  background: #fff;
  position: absolute;
  top: 7px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}


/* Image Mapping */

.image-mapping{
  padding-top: 50px;
}

.image-mapping img{
  width: auto !important;
  height: auto !important;
  max-width: inherit;
}

.image-mapping map area:hover{
  border: 1px solid red;
}

.img-wrapper{
  overflow: auto;
  width: 100%;
}
/* ************************************** */

@media (min-width: 1400px) {
  .container {
    max-width: 1226px;
  }
}

@media (max-width:1500px) {
  .colletion-box .btns-wrapper button{
    font-size: 14px;
    padding: 6px 12px;
  }

  .colletion-box > img, .colletion-box > a > img{
    height: 400px;
  }
}

@media (max-width:1439px) {
  .approve-kyc-wrap .filters form .radio-wrapper .form-group{
    width: calc(33.33% - 10px);
    margin-right: 10px;
  }
}

@media (max-width:1199px) {
  .approve-kyc-wrap .filters form .radio-wrapper{
    width: 100%;
    margin-bottom: 24px;
  }

  .approve-kyc-wrap .filters form{
    flex-direction: column;
    align-items: flex-start;
  }

  .approve-kyc-wrap .filters form .radio-wrapper .form-group{
    width: auto;
    margin-right: 30px;
  }

  .approve-kyc-wrap .filters form > .form-group{
    width: 100%;
  }

  .navbar-toggler {
    outline: none !important;
    padding: 0;
    box-shadow: none !important;
    border: none;
    position: relative;
    z-index: 1;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 30px;
    height: 4px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
    display: block;
  }

  .bar1 {
    width: 15px;
    margin-left: auto;
  }

  .bar2 {
    width: 20px;
    margin-left: auto;
  }

  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
    width: 30px;
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    transform: translate(0, -9px) rotate(45deg);
  }

  header .collapse:not(.show),
  .navbar-collapse {
    background-color: #010105;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    padding: 100px 0 50px;
    display: block;
    transition: 0.4s;
  }

  .navbar-collapse {
    right: 0;
  }
  
    .public-stage-wrapper .inner .limit-wrap {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

  header ul {
    gap: 30px;
  }

  header .wallet-btn {
    font-size: 18px;
    line-height: 24px;
    height: 50px;
  }

  h1 {
    font-size: 40px;
    line-height: 60px;
  }

  h1 span {
    font-size: 50px;
  }

  .banner p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 35px;
  }

  .activity .activity-slider h3 {
    font-size: 30px;
    line-height: 45px;
  }

  .activity .activity-slider .thumbnail {
    height: 500px;
  }

  .market .detail-card h5 {
    font-size: 16px;
  }

  .market .detail-card .buy-now-btn {
    font-size: 16px;
    line-height: 21px;
    gap: 10px;
    padding: 0 20px;
  }

  .market .detail-card .price {
    font-size: 16px;
    line-height: 24px;
  }

  .account .left{
    width: 50%;
  }

  .account .right{
    width: 50%;
  }

  .account .right > span{
    font-size: 100px;
  }

  .account.sidebar .right{
    width: 70%;
  }

  .account.sidebar .left{
    width: 30%;
  }

  .form-wrap{
    margin: 70px auto 30px;
  }
}

@media (max-width:991px) {
  header.topbar form, .form-wrap, .dao-proposal .container .inner, .approve-kyc-wrap .inner{
      box-shadow: 0px 1px 6px rgba(255,255,255, 0.3);
  }

  header.topbar .navbar .logo{
    display: inline-block;
    margin-right: auto;
  }

  .container{
    padding: 0 20px;
  }

  .bg-open.active {
    display: block;
  }

  .sidebar-arrow {
    display: flex;
  }

  .account.sidebar .right header.topbar .container{
    max-width: 100%;
    padding: 0 20px;
  }

  .account .left{
    width: 100%;
    padding: 30px 20px;
    box-shadow: inset 0px 30px 300px 70px rgba(170, 20, 240, 0.20);
    background-color: #030207;
  }

  .account.kyc .right,
  .account.signup .right,
  .account.signin .right {
    display: none;
  }

  .account .left .inner{
    max-width: 375px;
  }

  .account.sidebar .right{
    width: 100%;
    padding: 20px 0;
  }

  .account.sidebar .left{
    box-shadow: inset 0px 30px 100px 30px rgba(170, 20, 240, 0.2);
    position: fixed;
    left: -310px;
    transition: all ease-in-out 0.3s;
    top: 0;
    height: 100vh;
    overflow: auto;
    z-index: 10;
    width: 310px;
  }

  .account.sidebar .left .navbar-nav{
    width: 100%;
    max-width: 100%;
  }
  .account.sidebar .left.open {
    left: 0;
  }

  .account .right{
    box-shadow: inset 0px 10px 150px 40px rgba(170, 20, 240, 0.20);
  }

  .public-stage-wrapper .inner .limit-wrap {
    flex-direction: row;
    gap: 0;
    text-align: left;
  }

  h1 {
    font-size: 30px;
    line-height: 50px;
  }

  h1 span {
    font-size: 35px;
  }

  p {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .btns a {
    font-size: 16px;
    line-height: 24px;
    height: 45px;
    padding: 10px 24px;
    cursor: pointer;
  }

  .banner ul {
    gap: 50px;
  }

  .banner h6 {
    font-size: 21px;
    line-height: 24px;
  }

  .banner ul span {
    font-size: 16px;
    line-height: 21px;
  }

  .banner {
    background: url(../public/images/banner-bg.jpg) no-repeat;
    background-size: cover;
    padding: 150px 0 100px;
  }

  .about,
  .marketplace,
  footer {
    padding: 40px 0;
  }

  h2 {
    font-size: 30px;
    line-height: 35px;
  }

  .about p {
    margin-bottom: 30px;
  }

  .about a,
  .marketplace a {
    font-size: 16px;
    line-height: 24px;
  }

  .title-row {
    margin-bottom: 50px !important;
  }

  .work .work-card h4,
  .projects .project-card h4 {
    font-size: 18px;
    line-height: 24px;
  }

  .activity .activity-slider .thumbnail {
    height: 400px;
  }

  .activity .activity-slider .video-btn {
    width: 80px;
    height: 80px;
  }

  .activity .activity-slider h3 {
    font-size: 21px;
    line-height: 35px;
  }

  .activity .activity-slider .content {
    bottom: 18px;
    left: 30px;
  }

  .activity .activity-slider .item {
    margin: 0 24px 0 0;
  }

  .video-btn img {
    height: 30px;
  }

  footer .footer-logo {
    font-size: 35px;
    line-height: 45px;
  }

  footer h5 {
    font-size: 18px;
    line-height: 21px;
  }

  footer ul li a {
    font-size: 16px;
    line-height: 24px;
  }

  

  .market {
    padding: 150px 0 50px;
  }

  .collection-detail .collection-main-info{
    padding-left: 0;
    margin-top: 40px;
  }
}

@media (max-width:767px) {

  .colletion-box .btns-wrapper button {
    font-size: 16px;
    padding: 6px 16px;
  }

  .colletion-box > img, .colletion-box > a > img{
    height: auto;
    min-height: 280px;
  }

  .collection-wrap .collection-box > img{
    height: auto;
  }

  .public-stage-wrapper .inner .limit-wrap {
    flex-direction: column;
    gap: 20px;
    text-align: center;
}

  .work .work-card,
  .projects .project-card,
  .market .detail-card {
    max-width: 336px;
    margin: 0 auto 30px;
  }

  .activity .activity-slider .video-btn {
    width: 70px;
    height: 70px;
  }

  .video-btn img {
    height: 20px;
  }

  .activity .activity-slider .thumbnail {
    height: 300px;
  }
}

@media (max-width:640px) {
  header.topbar .profile span{
    display: none;
  }

  header.topbar .profile{
    margin-left: 24px;
  }
  
}

@media (max-width:480px) {
  .account .left .inner .logo{
    margin-bottom: 65px;
  }

  .form-wrap .nav-tabs{
    max-width: 220px;
  }

  .form-wrap .nav-tabs li{
    flex-grow: 1;
  }

  .form-wrap .nav-tabs li button{
    display: block;
    width: 100%;
  }
  header.topbar form{
    padding: 16px 12px 16px 35px;
    background-position: 8px 48%;
  }

  header.topbar .profile{
    margin-left: 16px;
  }

  header.topbar form button{
    width: 80px !important;
  }

  .form-wrap{
    padding: 24px;
  }
  
  .form-wrap.minting form .form-group{
    padding: 12px;
  }

  .modal.minting-engine .modal-body {
    padding: 15px 20px 48px;
  }
}